// 土地托管统计相关接口
import service from "../request";

const baseUrl = window.globalUrl.HOME_API;

//获取托管组织排名//调用接口数据
export const getTGZZInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/groupOrderCount`,
    params: params,
  });
};

//获取托管面积占比
export const getTGMJInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/overOrderCount`,
    params: params,
  });
};

//获取政策法规
export const getZCFGInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/cms/article/page`,
    //url: `${baseUrl}/land-trustship/article/getList`,
    params: params,
  });
};

//获取下载专区
export const getXZZQInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/cms/article/page`,
    params: params,
  });
};

//获取下载地址
export const getSingleContentInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/cms/file/` + params.id,
    params: params,
  });
};

//获取行政区划
export const getXzqhInfoToCity = () => {
  return service({
    url: `${baseUrl}/trade-website/cqjyService/getXzqhInfoToCity`,
    method: 'post',
    data: {},
  })
}

//分页查询已发布的资源资产供应信息
export const getPushXmxxByPg = (params) => {
  return service.post(`${baseUrl}/trade-website/cqjy/queryPushXmxxByPg`, params)
}

//保存资源资产推送信息
export const saveTs = (params) => {
  return service.post(`${baseUrl}/trade-website/cqjy/saveTs`, params)
  // return service({
  //   method: "post",
  //   url: `${baseUrl}/trade-website/cqjy/saveTs`,
  //   params: params,
  // });
}

//获取可推送用户信息
export const getUserName = (params) => {
  return service.get(`${baseUrl}/admin/user/getUserName`, { params })
  // return service({
  //   url: `${baseUrl}/admin/user/getUserName`,
  //   method: 'get',
  //   data: params,
  // })
}
